var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-main",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.sys.user.title.editHeadTitle")
              : _vm.$t("cip.plat.sys.user.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-cancel": _vm.headCancel,
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { dataForm: _vm.form, group: _vm.group },
      }),
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { staticClass: "roleTable", attrs: { name: "role" } },
            [
              _c("deptTable", {
                ref: "deptTable",
                attrs: { userFrom: _vm.form, deptDicDataList: _vm.deptDicData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }