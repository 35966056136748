var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "角色信息",
        },
        on: { "head-configureRolesInBulk": _vm.configureRolesInBulk },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "grid-row-btn": _vm.gridRowBtn,
          "table-loading": _vm.tableLoading,
        },
        on: { "row-configureRoles": _vm.configureRoles },
      }),
      _vm.roleBox
        ? _c(
            "CommonDialog",
            {
              attrs: {
                showFullscreen: true,
                dialogTitle: _vm.$t("cip.plat.sys.user.field.roleConfig"),
                width: "90%",
              },
              on: {
                cancel: function ($event) {
                  _vm.roleBox = false
                },
                confirm: _vm.submitRole,
              },
            },
            [
              _c("userDialog", {
                ref: "userDialog",
                attrs: { userFrom: _vm.form },
                on: { submitList: _vm.submitList },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }