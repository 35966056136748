<script src="../../api/index/mdIndexThemeCuboid.js"></script>
<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type=='edit'?$t('cip.plat.sys.user.title.editHeadTitle'):$t('cip.plat.sys.user.title.addHeadTitle')"
                 @head-save="headSave('save')" @head-cancel="headCancel" @head-save-back="headSave('back')">
    </head-layout>
    <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
              :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
    <form-layout ref="formLayout" :dataForm="form" :group="group"></form-layout>
    <!--        <upload-layout ref="upload" :queryParams="uploadQueryParams" v-if="uploadView" :dicDataBoolean="false" attachType="BUILD_STAGE_TYPE" attachCode="1,2" :attachId="attachId"></upload-layout>-->
    <el-collapse accordion v-model="activeNames">
      <el-collapse-item class="roleTable" name="role">
<!--        <template slot="title">
          <i class="el-icon-document"></i>角色信息
        </template>-->
        <deptTable ref="deptTable" :userFrom="form" :deptDicDataList="deptDicData"></deptTable>
        <!--        <userDialog-->
        <!--          ref="userDialog"-->
        <!--          :userFrom="form"-->
        <!--          @submitList="submitList"-->
        <!--        ></userDialog>-->
      </el-collapse-item>
    </el-collapse>

  </el-main>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import {getDeptTree} from "@/api/system/dept";
import {getRoleTree} from "@/api/system/role";
import HeadLayout from "@/views/components/layout/head-layout"
import UploadLayout from "@/views/components/layout/upload-layout"

import {add, getUser, update} from "@/api/system/user";
import website from '@/config/website';
import {mapGetters} from "vuex";
import userDialog from "@/views/system/userDialog";
import deptTable from "@/views/system/deptTable";
import {getNationality} from "@/api/system/region";

let deptListInput = {}
var DIC = {
  VAILD: [{
    label: '真',
    value: 'true'
  }, {
    label: '假',
    value: 'false'
  }],
  SEX: [{
    label: '男',
    value: 0
  }, {
    label: '女',
    value: 1
  }]
}

export default {
  name: "tab-form",
  components: {FormLayout, HeadLayout, UploadLayout, userDialog, deptTable},
  data() {
    // const validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error(this.$t("cip.plat.sys.user.field.inputPassword")));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      classCodeList: '',
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      postDicData: [],
      deptDicData: [],
      roleDicData: [],
      form: {},
      initFlag: true,
      formColumn: {},
      activeName: "first",
      btnOptions: {
        saveBtn: true,
        cancelBtn: true,
      },
      uploadView: false,
      uploadQueryParams: {},
      attachId: "",
      type: 'view',
      codeDisabled: false,
      dialogTitle: "所属部门",
      showDialog: false,
      treeList: [],
      deptList: [],
      deptDialogList: [],
      observer: null,
      activeNames: ['role'],
      // 默认国籍
      nationality: "中国",
    };
  },
  computed: {
    ...mapGetters(['language']),
    group() {
      return [
        {
          label: this.$t("cip.plat.sys.user.title.baseInfoTitle"),
          prop: 'baseInfo',
          icon: 'el-icon-document',
          column: [
            // {
            //   label: this.$t("cip.plat.sys.user.field.tenantName"),
            //   prop: "tenantId",
            //   span: 8,
            //   type: "tree",
            //   dicUrl: "/api/sinoma-system/tenant/select",
            //   props: {
            //     label: "tenantName",
            //     value: "tenantId"
            //   },
            //   hide: !website.tenantMode,
            //   addDisplay: website.tenantMode,
            //   editDisplay: website.tenantMode,
            //   viewDisplay: website.tenantMode,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.tenantName"),
            //     trigger: "click"
            //   }],
            //
            // },
            {
              label: this.$t("cip.plat.sys.user.field.account"),
              prop: "account",
              span: 8,
              maxlength: 20,
              showWordLimit: true,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
                trigger: "blur"
              },
                {
                  pattern: /[a-zA-Z0-9]$/,
                  message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
                },
              ],
            },
            {
              label: this.$t("cip.plat.sys.user.field.code"),
              prop: "code",
              span: 8,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.code"),
                trigger: "blur"
              },
                {
                  pattern: /[a-zA-Z0-9]$/,
                  message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
                },
              ],
            },
            {
              label: this.$t(`cip.cps.device.field.netType.orderNum`),
              prop: "sort",
              type: "number",
              rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.cps.device.field.netType.orderNum`),
                  trigger: "blur",
                }
              ],
            },
            // {
            //   label: this.$t("cip.plat.sys.user.field.userType"),
            //   type: "select",
            //   dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            //   props: {
            //     label: "dictValue",
            //     value: "dictKey"
            //   },
            //   span: 8,
            //   value: 1,
            //   dataType: "number",
            //   slot: true,
            //   prop: "userType",
            //   disabled:true,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.userType"),
            //     trigger: "blur"
            //   }],
            //   change: ({value}) => {
            //     this.getRoleTreeByUserType(website.tenantId, value)
            //   }
            // },
            // {
            //   label: this.$t("cip.plat.sys.user.field.password"),
            //   prop: 'password',
            //   hide: true,
            //   display: true,
            //   span: 8,
            //   maxlength: 20,
            //   showWordLimit: true,
            //   editDisplay: false,
            //   viewDisplay: false,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.plat.sys.user.field.inputPassword"),
            //     trigger: 'blur'
            //   },
            //   {
            //     pattern: /^[^\s]*$/,
            //     message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            //   }
            // ]
            // },
            // {
            //   label: this.$t("cip.plat.sys.user.field.password2"),
            //   prop: 'password2',
            //   display: true,
            //   span: 8,
            //   maxlength: 20,
            //   showWordLimit: true,
            //   hide: true,
            //   editDisplay: false,
            //   viewDisplay: false,
            //   rules: [{
            //     required: true,
            //     validator: this.validatePass2,
            //     trigger: 'blur'
            //   },
            //   {
            //     pattern: /^[^\s]*$/,
            //     message: this.$t("cip.cmn.rule.removeSpacesWarning"),
            //   }
            // ]
            // },
            // {
            //   label: this.$t("cip.plat.sys.user.field.gestureUnlock"),
            //   prop: "gestureUnlock",
            //   span: 8,
            //   readonly: true,
            //   placeholder: this.$t(`cip.plat.sys.user.field.voluntarily`),
            // },
            // {
            //   label: this.$t("cip.plat.sys.user.field.passwordUnlock"),
            //   prop: "passwordUnlock",
            //   span: 8,
            //   readonly: true,
            //   placeholder: this.$t(`cip.plat.sys.user.field.voluntarily`),
            // },
            {
              label: this.$t("cip.plat.sys.user.field.isEnabled"),
              prop: "isEnabled",
              span: 8,
              type: "select",
              clearable: false,//清空按钮
              value: 0,
              // dicData: [
              //   {
              //     label: this.$t("cip.plat.sys.user.field.enable"),
              //     value: 0
              //   },
              //   {
              //     label: this.$t("cip.plat.sys.user.field.disable"),
              //     value: 1
              //   }
              // ],
              dataType: 'number',
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            },
            {
              label: "国籍",
              prop: "nationality",
              span: 8,
              type: "tree",
              props: {
                label: 'name',
                value: 'code'
              },
              dicUrl: '/api/sinoma-system/region/selectByLevel?regionLevel=1',
              rules: [
                {
                  required: true,
                  message: "请选择国籍",
                  trigger: ["blur", "change"],
                }
              ],
            },
            {
              label: "所在地",
              prop: "personnelLocation",
              span: 8,
              type: "radio",
              value: 1,
              dataType: 'number',
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_location",
              rules: [
                {
                  required: true,
                  message: "请选择所在地",
                  trigger: ["blur", "change"],
                }
              ],
            },
            {
              label: "人员类型",
              prop: "personnelType",
              span: 8,
              type: "select",
              dataType: 'number',
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
              rules: [
                {
                  required: true,
                  message: "请选择人员类型",
                  trigger: ["blur", "change"],
                }
              ],
            },
          ]
        },
        {
          label: this.$t("cip.plat.sys.user.title.detailInfoTitle"),
          prop: 'detailInfo',
          icon: 'el-icon-document',
          column: [
            // {
            //   label: this.$t("cip.plat.sys.user.field.name"),
            //   prop: "name",
            //   span: 8,
            //   hide: true,
            //   isGlobal: true, //是否支持国际化
            //   rules: [{
            //     required: true,
            //     validator: this.validateName,
            //     trigger: ["blur","change"]
            //   }]
            // },
            {
              label: this.$t("cip.plat.sys.user.field.realName"),
              prop: "realName",
              span: 8,
              isGlobal: true, //是否支持国际化
              rules: [{
                required: true,
                validator: this.validateRealName,
                trigger: ["blur", "change"]
              }]
            },
            {
              label: this.$t("cip.plat.sys.user.field.phone"),
              prop: "phone",
              span: 8,
              maxlength: 20,
              showWordLimit: true,
              overHidden: true,
              rules: [{
                required: true,
                pattern: /^1[0-9]{10}$/,
                message: this.$t("cip.plat.sys.user.field.contactNumber"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.sys.user.field.email"),
              prop: "email",
              span: 8,
              hide: true,
              maxlength: 30,
              showWordLimit: true,
              overHidden: true
            },
            {
              label: this.$t("cip.plat.sys.user.field.sex"),
              prop: "sex",
              span: 8,
              type: "select",
              // dicData: [
              //   {
              //     label: this.$t("cip.plat.sys.user.field.man"),
              //     value: 1
              //   },
              //   {
              //     label: this.$t("cip.plat.sys.user.field.women"),
              //     value: 2
              //   },
              //   {
              //     label: this.$t("cip.plat.sys.user.field.unknow"),
              //     value: -1
              //   }
              // ],
              dataType: 'number',
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
              hide: true
            },
            {
              label: this.$t("cip.plat.sys.user.field.identityCard"),
              prop: "identityCard",
              //暂留  后续编辑不能被修改
              // disabled: ['edit'].includes(this.type),
              span: 8,
              placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t(`cip.plat.sys.user.field.identityCard`),
              rules: [{
                pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                message: this.$t("cip.cmn.rule.identityCardWarning"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.sys.user.field.birthday"),
              type: "date",
              span: 8,
              prop: "birthday",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd hh:mm:ss",
              hide: true
            },

          ]
        },
        {
          label: this.$t("cip.plat.sys.user.title.dutyInfoTitle"),
          prop: 'dutyInfo',
          icon: 'el-icon-document',
          column: [
            // {
            //   label: this.$t("cip.plat.sys.user.field.code"),
            //   prop: "code",
            //   span: 8,
            //   maxlength: 20,
            //   showWordLimit: true,
            //   disabled: ('edit').includes(this.type) || this.codeDisabled,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.code"),
            //     trigger: "click"
            //   },
            // ]
            // },
            // {
            //   label: this.$t("cip.plat.sys.user.field.roleName"),
            //   prop: "roleId",
            //   span: 8,
            //   multiple: true,
            //   type: "tree",
            //   dicData: this.roleDicData,
            //   props: {
            //     label: "title"
            //   },
            //   checkStrictly: true,
            //   slot: true,
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.roleName"),
            //     trigger: "click"
            //   }]
            // },
            {
              label: this.$t("cip.plat.sys.user.field.deptName"),
              prop: "deptId",
              span: 16,
              type: "tree",
              multiple: true,
              dicData: this.deptDicData,
              props: {
                label: "title"
              },
              checkStrictly: true,
              slot: true,
              change(row) {
                var toString = row.value.toString();
                row.value = toString.split(",")
                deptListInput = row

              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.deptName"),
                trigger: ["click", "change"]
              }]
            },
            // {
            //   label: this.$t("cip.plat.sys.user.field.postId"),
            //   prop: "postId",
            //   span: 8,
            //   type: "tree",
            //   multiple: true,
            //   dicData: this.postDicData,
            //   hide: true,
            //   props: {
            //     label: "postName",
            //     value: "id"
            //   },
            //   rules: [{
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.postId"),
            //     trigger: ["click", "change"]
            //   }],
            // },
          ]
        },
        // {
        //   label: this.$t("cip.plat.sys.user.title.extendTitle"),
        //   prop: 'extend',
        //   icon: 'el-icon-document',
        //   column: [
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.subjectName"),
        //       prop: "subjectCode",
        //       span: 8,
        //       type: "tree",
        //       dataType:"string",
        //       multiple: true,
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'subjectName',
        //         value: 'subjectCode',
        //         key: 'subjectCode',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/mdsubject/getList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("subjectCode", "subjectCode", value, [
        //           {
        //             code:"subjectName",
        //             value:"subjectName"
        //           }
        //         ])
        //       },
        //     },
        //
        //
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.postLevelName"),
        //       prop: "postLevelCode",
        //       span: 8,
        //       type: "tree",
        //       multiple: true,
        //       dataType:"string",
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'postLevelName',
        //         value: 'postLevelCode',
        //         key: 'postLevelCode',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/mdpostlevel/getList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("postLevelCode", "postLevelCode", value, [
        //           {
        //             code:"postLevelName",
        //             value:"postLevelName"
        //           }
        //         ])
        //       },
        //     },
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.dutyName"),
        //       prop: "dutyCode",
        //       span: 8,
        //       type: "tree",
        //       dataType:"string",
        //       multiple: true,
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'dutyName',
        //         value: 'dutyCode',
        //         key: 'dutyCode',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/mdduty/getList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("dutyCode", "dutyCode", value, [
        //           {
        //             code:"dutyName",
        //             value:"dutyName"
        //           }
        //         ])
        //       },
        //     },
        //     {
        //       label: this.$t("cip.plat.sys.user.field.shiftTypeName"),
        //       prop: "shiftTypeCode",
        //       span: 8,
        //       type: "tree",
        //       multiple: true,
        //       dataType:"string",
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'shiftTypeName',
        //         value: 'id',
        //         key: 'id',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/mdshifttype/getList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("shiftTypeCode", "id", value, [
        //           {
        //             code:"shiftTypeName",
        //             value:"shiftTypeName"
        //           }
        //         ])
        //       },
        //     },
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.areaName"),
        //       prop: "areaCode",
        //       span: 8,
        //       type: "tree",
        //       dataType:"string",
        //       multiple: true,
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'areaName',
        //         value: 'areaCode',
        //         key: 'areaCode',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/md/area/list`,
        //       change:({value}) => {
        //         this.multipleTreeChange("areaCode", "areaCode", value, [
        //           {
        //             code:"areaName",
        //             value:"areaName"
        //           }
        //         ])
        //       },
        //     },
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.workSectName"),
        //       prop: "workSectCode",
        //       span: 8,
        //       type: "tree",
        //       dataType:"string",
        //       multiple: true,
        //       // dicData: this.deptDicData,
        //       props: {
        //         label: 'sectName',
        //         value: 'sectCode',
        //         key: 'sectCode',
        //       },
        //       checkStrictly: true,
        //       slot: true,
        //       dicUrl: `/api/sinoma-md/mdsectprocess/getList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("workSectCode", "sectCode", value, [
        //           {
        //             code:"workSectName",
        //             value:"sectName"
        //           }
        //         ])
        //       },
        //     },
        //
        //     {
        //       label: this.$t("cip.plat.sys.user.field.className"),
        //       prop: "classCode",
        //       span: 8,
        //       type: "tree",
        //       dataType:"string",
        //       props: {
        //         label: 'className',
        //         value: 'classCode',
        //         key: 'classCode',
        //       },
        //       multiple: true,
        //       checkStrictly: true,
        //       dicUrl: `/api/sinoma-md/md/class/selectList`,
        //       change:({value}) => {
        //         this.multipleTreeChange("classCode","classCode", value, [
        //           {
        //             code:"className",
        //             value:"className"
        //           }
        //         ])
        //       },
        //     },
        //   ]
        // },

      ]
    }
  },
  watch: {
    'form.tenantId'() {
      if (this.form != null && this.form.tenantId !== '' && this.initFlag) {
        this.initData(this.form.tenantId);
      }

    },
    'excelForm.isCovered'() {
      if (this.excelForm.isCovered !== '') {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/sinoma-user/import-user?isCovered=${this.excelForm.isCovered}`;
      }
    },
    form(val) {
      // let dicData = this.$refs.formLayout.getGrid().DIC["deptId"];

      // this.$refs.formLayout.$refs.form.DIC['deptId'];
      // let dicData = this.$refs.formLayout.$refs.form.DIC["deptId"];
      // console.log("进行输出-------------------", this)
      // console.log("dicData",dicData)
    },
  },
  created() {

    if (this.$route.query.tenantStatus != "true") {
      // this.findObject(this.group, "tenantId").display = false
      this.form.tenantId = "000000";
    }


  },
  mounted() {
    let {id, type} = this.$route.query;
    this.type = type;
    // this.$refs.userDialog.getDataList();
    if (this.$route.query.type == 'edit') {
      this.form.id = this.$route.query.id

      //密码 不能修改
      // const column = this.findObject(this.group, "password");
      // column.display = false;
      // const column2 = this.findObject(this.group, "password2");
      // column2.display = false;
      this.headBtnOptions[0].label = this.$t('cip.cmn.btn.revBtn')
    }
    if(this.type == 'add' && this.$route.query.row){
      this.addInitData()
    }
    if(this.type === 'add'){
      this.getNationality()
    }
    this.initData(website.tenantId);
    this.listenerDom()
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    listenerDom() {
      // 目标DOM元素
      const targetElement = document.getElementsByClassName(
        "el-input el-input--small el-input--suffix"
      );
      // 创建观察器对象
      this.observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "attributes") {
            // 只处理属性变化
            if (mutation.target.className.indexOf("is-focus") == -1) {
              console.log("进行输出-----------")
              let deptList = []
              this.treeList = deptListInput.value;
              const column = this.findObject(this.group, "deptId");
              var item = this.deptDicData;
              this.treeList.forEach(e => {
                //查询上级部门
                // let findParentNode = this.findParentNode(column.dicData, e);
                //查询本级部门
                let findChildData = this.findChildData(item, e);
                if (findChildData != null) {
                  let findParentNode1 = {}
                  findParentNode1.departmentId = findChildData.id
                  findParentNode1.department = findChildData.title
                  findParentNode1.deptAncestor = findChildData.organizeIds
                  findParentNode1.superiorDepartment = findChildData.title
                  findParentNode1.superiorDepartmentId = findChildData.id
                  findParentNode1.isDefaultDept = "0"
                  deptList.push(findParentNode1);
                }
                  // else {
                //   let find = {}
                //   find.departmentId = findChildData.id
                //   find.department = findChildData.title
                //   find.deptAncestor = findChildData.organizeIds
                //   // find.superiorDepartment = findParentNode.title
                //   // find.superiorDepartmentId = findParentNode.id
                //   find.isDefaultDept = "0"
                //   this.deptList.push(find);
                // }
                //查询出所有的部门
              })
              // this.$refs.userDialog.deptList = this.deptList;
              // console.log("listenerDomlistenerDom",deptList)
              if(deptList.length >0){
                this.$refs.deptTable.deptTableList(deptList);
              }
            }
          }
        }
      });
      // 配置观察选项
      const config = {attributes: true};
      // 开始观察目标元素
      this.observer.observe(targetElement[11], config);
    },
    submitList(row) {
      this.deptDialogList = row

      this.showDialog = false
    },
    onSubmit() {

    },
    findParentNode(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          // 判断当前节点的子节点是否包含目标子节点
          const isChild = node.children.some(child => child.id === childId);
          if (isChild) {
            // 如果存在目标子节点，返回当前节点
            return node;
          } else {
            // 否则递归查找当前节点的子节点
            const parentNode = this.findParentNode(node.children, childId);
            if (parentNode) {
              // 如果找到了目标子节点的上一级节点，返回该节点
              return parentNode;
            }
          }
        }
      }
      // 没有找到目标子节点的上一级节点，返回 null
      return null;
    },
    // /**
    //  * form多选框change事件赋值
    //  * @param prop  当前字段的类型
    //  * @param dicCode 当前字段使用字典的value名称
    //  * @param value chang事件的参数
    //  * @param linkageProp 需要赋值的form表单其他的字段对应：code对应form的字段，value对应字典的字段
    //  */
    // multipleTreeChange(prop, dicCode, value, linkageProp){
    //   let ref = this.$refs.formLayout;
    //   if(ref) {
    //     let dicData = ref.getForm().DIC[prop];
    //     linkageProp.forEach(e => {
    //       ref.dataForm[e.code] = value.split(",").map(v => {
    //         let node = findNodeByDic(dicData, v, dicCode)
    //         if (node){
    //           return node[e.value]
    //         } else {
    //           return undefined
    //         }
    //       }).filter(e => e).join(",")
    //     })
    //   }
    // },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t("cip.plat.sys.user.field.checkPassword")));
      } else if (value !== this.$refs.formLayout.$refs.form.form.password) {
        callback(new Error(this.$t("cip.plat.sys.user.field.uncheckPassword")));
      } else {
        callback();
      }
    },
    // validateName(rule, value, callback){
    //   if (value != '') {
    //     let val = JSON.parse(value)
    //     let val2 = val.zh
    //     if (val2.substring(0,1) == " " || val2.substring(val2.length-1) == " ") {
    //       callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
    //     }
    //     if (val2.length > 20) {
    //       callback(new Error(this.$t("cip.plat.sys.user.field.name") + this.$t("cip.plat.sys.user.msg.nickName")));
    //     } else {
    //       callback();
    //     }
    //   } else {
    //     callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.msg.nameLength")));
    //   }
    // },
    validateRealName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length < 2 || val2.length > 50) {
          callback(new Error(this.$t("cip.plat.sys.user.msg.nameLength")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName")));
      }
    },
    headSave(type) {
      //进行获取部门角色
      var submit = this.$refs.deptTable.submitData();
      console.log("进行输出-submit----",submit)
      this.$refs.formLayout.$refs.form.form.account = this.$refs.formLayout.$refs.form.form.account.trim()
      this.$refs.formLayout.$refs.form.allDisabled = true
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          let form = this.$refs.formLayout.$refs.form.form


          if (Array.isArray(form.deptId)) {
            form.deptId = form.deptId.join(",");
          }
          if (Array.isArray(form.roleId)) {
            form.roleId = form.roleId.join(",");
          }
          if (Array.isArray(form.postId)) {
            form.postId = form.postId.join(",");
          }
          this.$nextTick(() => {
            form.roleIds = []
            form.roleIds = submit
          })
          let requestType = form.hasOwnProperty('id') ? update : add
          requestType(form).then((res) => {
            if (type == 'back') {
              this.headCancel()
            }
            // this.$refs.upload.updateRow( this.$route.query.id,function(resBak){
            //   console.log(resBak);
            // });
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            this.form.id = res.data.data.id;
            this.getUserInfo();
            this.$refs.formLayout.$refs.form.allDisabled = false

          }, error => {
            window.console.log(error);
            this.$refs.formLayout.$refs.form.allDisabled = false
          }).finally(() => {
            this.$loading().close()
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
      this.showDialog = false
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    getUserInfo() {
      getUser(this.form.id).then(res => {
        console.log(res, '413')
        this.form = res.data.data;
      });
    },
    getRoleTreeByUserType(tenantId, userType) {
      getRoleTree(tenantId, userType).then(res => {
        const column = this.findObject(this.group, "roleId");
        column.dicData = res.data.data;
      });
    },
    initData(tenantId) {
      // getRoleTree(tenantId).then(res => {
      //   const column = this.findObject(this.group, "roleId");
      //   column.dicData = res.data.data;
      // });
      if (this.type != 'add') {
        this.getUserInfo();
      }
      getDeptTree(tenantId).then(res => {
        const column = this.findObject(this.group, "deptId");
        this.deptDicData = res.data.data;
        column.dicData = res.data.data;
        if (this.type != 'add'){
          if (this.form.personnelLocation == "-1") {
            this.form.personnelLocation = "";
          }
          if (this.form.personnelType == "-1") {
            this.form.personnelType = "";
          }
          let deptList = []
          var itemDeptTree = this.deptDicData;
          this.form.roleIds.forEach((item) => {
            let findChildData = this.findChildData(itemDeptTree, item.deptId);
            let findParentNode1 = {}
            if(findChildData !=null){
              findParentNode1.departmentId = item.deptId
              findParentNode1.department = item.deptName
              findParentNode1.superiorDepartment = item.deptAncestorName
              findParentNode1.superiorDepartmentId = item.deptAncestor
              findParentNode1.deptAncestor = findChildData.organizeIds
              findParentNode1.isDefaultDept = item.isDefaultDept
              findParentNode1.roleId = item.roleId
              findParentNode1.roleName = item.roleName
              deptList.push(findParentNode1);
            }
          })
          //查询出所有的部门
          // this.$refs.userDialog.deptList = this.deptList;
          // this.$refs.userDialog.updateDeptList();
          console.log("进行输出-------deptList----",deptList)
          if(deptList.length>0){
            this.$refs.deptTable.deptTableroleList(deptList);
          }
          this.$nextTick(() => {
            //attachType  对应字典的code 如：BUILD_STAGE_TYPE    attachCode 对应字典的dictKey如：1,2   attachId 业务关联ID
            this.uploadQueryParams = {"attachId": this.form.id, "attachType": "BUILD_STAGE_TYPE", "attachCode": ""};
            this.uploadView = true
            this.$refs.formLayout.getForm().clearValidate();
          })

          if (this.form.hasOwnProperty("deptId")) {
            this.form.deptId = this.form.deptId.split(",");
          }
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",");
          }
          if (this.form.hasOwnProperty("postId")) {
            this.form.postId = this.form.postId.split(",");
          }
        }
      });
      // getPostList(tenantId).then(res => {
      //   const column = this.findObject(this.group, "postId");
      //   column.dicData = res.data.data;
      // });
    },
    addInitData(){
      let row = JSON.parse(decodeURIComponent(this.$route.query.row))
      this.$nextTick(()=>{
        this.form.account = row.account
        this.form.code = row.code
        this.form.sort = row.rank
        this.form.isEnabled = row.status

        this.form.realName = row.name ? JSON.stringify({"zh":row.name}) : null
        this.form.phone = row.phone
        this.form.email = row.email
        this.form.sex = row.sex ? Number(row.sex) : null
        this.form.identityCard = row.idNum
        this.form.birthday = row.birthday?row.birthday + ' 00:00:00' : ''
        this.$refs.formLayout.$refs.form.form = this.form
      })
    },
    handleBack() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    save(type) {
      this.$refs.formLayout.$refs.form.validate((valid, done, msg) => {
        if (valid) {
          let form = this.$refs.formLayout.$refs.form.form


          if (Array.isArray(form.deptId)) {
            form.deptId = form.deptId.join(",");
          }

          if (Array.isArray(form.roleId)) {
            form.roleId = form.roleId.join(",");
          }
          if (Array.isArray(form.postId)) {
            form.postId = form.postId.join(",");
          }

          if (this.type == 'edit') {
            update(form).then(() => {
              this.initFlag = false;
              if (type == 'back') {
                this.close()
              }
              this.dataObj = res.data.data
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
              done();
            }, error => {
              window.console.log(error);
              // loading();
            });
          } else {
            add(form).then(() => {
              this.initFlag = false;
              if (type == 'back') {
                this.close()
              }
              this.dataObj = res.data.data
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });

              done();
            }, error => {
              window.console.log(error);

              this.$refs.formLayout.$refs.form.allDisabled = false
            });
          }

        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })

    },
    getNationality() {
      getNationality(this.nationality).then(res => {
        this.$set(this.form, 'nationality', res.data.data)
      })
    }
  },
}
</script>

<style scoped>
.roleTable ::v-deep .el-collapse-item__header {
  font-size: 14px;
  color: #999999;
  font-weight: bold;
  padding: 0 10px 0 20px;
}

.roleTable ::v-deep .el-icon-document {
  font-size: 20px;
  margin-right: 8px;
}

.roleTable ::v-deep .el-icon-arrow-right {
  font-size: 13px;
  color: #303133;
}
</style>
